<template>
  <page-title class="q-mb-lg" :title="$t('account.bank_account')" />

  <q-intersection
    class="full-height default-shadow default-rounded bg-white full-height-intersection"
    transition="slide-up"
  >
    <div class="q-gutter-y-md">
      <q-tabs v-model="tab" dense class="bg-grey-11 q-pa-sm text-grey-8">
        <q-tab
          name="bank"
          icon="account_balance"
          label="Banco"
          v-if="!in_request || verifyBankTypes(['CC', 'ALL'])"
        />
        <q-tab name="pix" v-if="!in_request || verifyBankTypes(['PIX', 'ALL'])">
          <template v-slot:default>
            <svg
              width="1.5em"
              fill="#777777"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6
              391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4
              165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6
              120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9
              353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6
              241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6
              142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5
              353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76
              310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"
              />
            </svg>
            <div class="q-tab__label">Chave Pix</div>
          </template>
        </q-tab>
      </q-tabs>
    </div>

    <div class="" v-if="in_request">
      <q-tab-panels
        animated
        vertical
        swipeable
        v-model="tab"
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <q-tab-panel name="bank" v-if="verifyBankTypes(['CC', 'ALL'])">
          <bank-account-type-bank />
        </q-tab-panel>
        <q-tab-panel name="pix" v-if="verifyBankTypes(['PIX', 'ALL'])">
          <bank-account-type-pix />
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <div class="text-center margin-spinner" v-else>
      <q-spinner size="15em" :thickness="8" color="grey-6" />
    </div>
  </q-intersection>
</template>

<script>
import { useGetters } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import BankAccountTypeBank from "./bank-account-type-bank";
import BankAccountTypePix from "./bank-account-type-pix";
import { ref, onMounted } from "vue";

export default {
  name: "BankAccount",

  components: {
    BankAccountTypePix,
    BankAccountTypeBank,
    PageTitle,
  },

  setup() {
    const in_request = ref(true);

    const { me_store_params, partner } = useGetters({
        me_store_params: "account/getParams",
        partner: "account/getPartnerAccount",
      }),
      tab = ref("not-set");

    const getExternalKey = (obj) => {
        if (obj.payment_method) return obj.payment_method.value;
      },
      verifyBankTypes = (types) =>
        types.includes(getExternalKey(me_store_params.value));

    onMounted(async () => {
      tab.value = partner.value.bank_account ? "bank" : "pix";
    });

    return {
      tab,
      partner,
      in_request,
      getExternalKey,
      verifyBankTypes,
    };
  },
};
</script>

<style lang="scss">
/** fix bug when the value from q select is a object */
[value="[object Object]"] [type="search"] {
  color: transparent;
  font-size: 0 !important;
  &::-moz-selection {
    color: transparent;
    background: transparent;
  }
  &::selection {
    color: transparent;
    background: transparent;
  }
}

.margin-spinner {
  margin-top: 100px;
}
</style>
